<template>
	<div class="echarts">
		<div class="startstop">
			<StartStop :data="data" :xAxis="xAxis" title="混匀料槽9号仓圆盘给料机"/>
		</div>
		<div class="shishi">
			<div class="main" id="main"></div>
		</div>
		<div class="chat">
			<Gantt/>
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts'
import { onMounted, reactive, toRefs, ref } from 'vue'
import StartStop from '@/components/StartStop'
import Gantt from '@/components/gantt'

export default {
	components:{
		StartStop,
		Gantt
	},
	setup() {
		const state = reactive({
			data: [
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0
],
			xAxis:[
  "2024-03-18 00:00",
  "2024-03-18 00:01",
  "2024-03-18 00:02",
  "2024-03-18 00:03",
  "2024-03-18 00:04",
  "2024-03-18 00:05",
  "2024-03-18 00:06",
  "2024-03-18 00:07",
  "2024-03-18 00:08",
  "2024-03-18 00:09",
  "2024-03-18 00:10",
  "2024-03-18 00:11",
  "2024-03-18 00:12",
  "2024-03-18 00:13",
  "2024-03-18 00:14",
  "2024-03-18 00:15",
  "2024-03-18 00:16",
  "2024-03-18 00:17",
  "2024-03-18 00:18",
  "2024-03-18 00:19",
  "2024-03-18 00:20",
  "2024-03-18 00:21",
  "2024-03-18 00:22",
  "2024-03-18 00:23",
  "2024-03-18 00:24",
  "2024-03-18 00:25",
  "2024-03-18 00:26",
  "2024-03-18 00:27",
  "2024-03-18 00:28",
  "2024-03-18 00:29",
  "2024-03-18 00:30",
  "2024-03-18 00:31",
  "2024-03-18 00:32",
  "2024-03-18 00:33",
  "2024-03-18 00:34",
  "2024-03-18 00:35",
  "2024-03-18 00:36",
  "2024-03-18 00:37",
  "2024-03-18 00:38",
  "2024-03-18 00:39",
  "2024-03-18 00:40",
  "2024-03-18 00:41",
  "2024-03-18 00:42",
  "2024-03-18 00:43",
  "2024-03-18 00:44",
  "2024-03-18 00:45",
  "2024-03-18 00:46",
  "2024-03-18 00:47",
  "2024-03-18 00:48",
  "2024-03-18 00:49",
  "2024-03-18 00:50",
  "2024-03-18 00:51",
  "2024-03-18 00:52",
  "2024-03-18 00:53",
  "2024-03-18 00:54",
  "2024-03-18 00:55",
  "2024-03-18 00:56",
  "2024-03-18 00:57",
  "2024-03-18 00:58",
  "2024-03-18 00:59",
  "2024-03-18 01:00",
  "2024-03-18 01:01",
  "2024-03-18 01:02",
  "2024-03-18 01:03",
  "2024-03-18 01:04",
  "2024-03-18 01:05",
  "2024-03-18 01:06",
  "2024-03-18 01:07",
  "2024-03-18 01:08",
  "2024-03-18 01:09",
  "2024-03-18 01:10",
  "2024-03-18 01:11",
  "2024-03-18 01:12",
  "2024-03-18 01:13",
  "2024-03-18 01:14",
  "2024-03-18 01:15",
  "2024-03-18 01:16",
  "2024-03-18 01:17",
  "2024-03-18 01:18",
  "2024-03-18 01:19",
  "2024-03-18 01:20",
  "2024-03-18 01:21",
  "2024-03-18 01:22",
  "2024-03-18 01:23",
  "2024-03-18 01:24",
  "2024-03-18 01:25",
  "2024-03-18 01:26",
  "2024-03-18 01:27",
  "2024-03-18 01:28",
  "2024-03-18 01:29",
  "2024-03-18 01:30",
  "2024-03-18 01:31",
  "2024-03-18 01:32",
  "2024-03-18 01:33",
  "2024-03-18 01:34",
  "2024-03-18 01:35",
  "2024-03-18 01:36",
  "2024-03-18 01:37",
  "2024-03-18 01:38",
  "2024-03-18 01:39",
  "2024-03-18 01:40",
  "2024-03-18 01:41",
  "2024-03-18 01:42",
  "2024-03-18 01:43",
  "2024-03-18 01:44",
  "2024-03-18 01:45",
  "2024-03-18 01:46",
  "2024-03-18 01:47",
  "2024-03-18 01:48",
  "2024-03-18 01:49",
  "2024-03-18 01:50",
  "2024-03-18 01:51",
  "2024-03-18 01:52",
  "2024-03-18 01:53",
  "2024-03-18 01:54",
  "2024-03-18 01:55",
  "2024-03-18 01:56",
  "2024-03-18 01:57",
  "2024-03-18 01:58",
  "2024-03-18 01:59",
  "2024-03-18 02:00",
  "2024-03-18 02:01",
  "2024-03-18 02:02",
  "2024-03-18 02:03",
  "2024-03-18 02:04",
  "2024-03-18 02:05",
  "2024-03-18 02:06",
  "2024-03-18 02:07",
  "2024-03-18 02:08",
  "2024-03-18 02:09",
  "2024-03-18 02:10",
  "2024-03-18 02:11",
  "2024-03-18 02:12",
  "2024-03-18 02:13",
  "2024-03-18 02:14",
  "2024-03-18 02:15",
  "2024-03-18 02:16",
  "2024-03-18 02:17",
  "2024-03-18 02:18",
  "2024-03-18 02:19",
  "2024-03-18 02:20",
  "2024-03-18 02:21",
  "2024-03-18 02:22",
  "2024-03-18 02:23",
  "2024-03-18 02:24",
  "2024-03-18 02:25",
  "2024-03-18 02:26",
  "2024-03-18 02:27",
  "2024-03-18 02:28",
  "2024-03-18 02:29",
  "2024-03-18 02:30",
  "2024-03-18 02:31",
  "2024-03-18 02:32",
  "2024-03-18 02:33",
  "2024-03-18 02:34",
  "2024-03-18 02:35",
  "2024-03-18 02:36",
  "2024-03-18 02:37",
  "2024-03-18 02:38",
  "2024-03-18 02:39",
  "2024-03-18 02:40",
  "2024-03-18 02:41",
  "2024-03-18 02:42",
  "2024-03-18 02:43",
  "2024-03-18 02:44",
  "2024-03-18 02:45",
  "2024-03-18 02:46",
  "2024-03-18 02:47",
  "2024-03-18 02:48",
  "2024-03-18 02:49",
  "2024-03-18 02:50",
  "2024-03-18 02:51",
  "2024-03-18 02:52",
  "2024-03-18 02:53",
  "2024-03-18 02:54",
  "2024-03-18 02:55",
  "2024-03-18 02:56",
  "2024-03-18 02:57",
  "2024-03-18 02:58",
  "2024-03-18 02:59",
  "2024-03-18 03:00",
  "2024-03-18 03:01",
  "2024-03-18 03:02",
  "2024-03-18 03:03",
  "2024-03-18 03:04",
  "2024-03-18 03:05",
  "2024-03-18 03:06",
  "2024-03-18 03:07",
  "2024-03-18 03:08",
  "2024-03-18 03:09",
  "2024-03-18 03:10",
  "2024-03-18 03:11",
  "2024-03-18 03:12",
  "2024-03-18 03:13",
  "2024-03-18 03:14",
  "2024-03-18 03:15",
  "2024-03-18 03:16",
  "2024-03-18 03:17",
  "2024-03-18 03:18",
  "2024-03-18 03:19",
  "2024-03-18 03:20",
  "2024-03-18 03:21",
  "2024-03-18 03:22",
  "2024-03-18 03:23",
  "2024-03-18 03:24",
  "2024-03-18 03:25",
  "2024-03-18 03:26",
  "2024-03-18 03:27",
  "2024-03-18 03:28",
  "2024-03-18 03:29",
  "2024-03-18 03:30",
  "2024-03-18 03:31",
  "2024-03-18 03:32",
  "2024-03-18 03:33",
  "2024-03-18 03:34",
  "2024-03-18 03:35",
  "2024-03-18 03:36",
  "2024-03-18 03:37",
  "2024-03-18 03:38",
  "2024-03-18 03:39",
  "2024-03-18 03:40",
  "2024-03-18 03:41",
  "2024-03-18 03:42",
  "2024-03-18 03:43",
  "2024-03-18 03:44",
  "2024-03-18 03:45",
  "2024-03-18 03:46",
  "2024-03-18 03:47",
  "2024-03-18 03:48",
  "2024-03-18 03:49",
  "2024-03-18 03:50",
  "2024-03-18 03:51",
  "2024-03-18 03:52",
  "2024-03-18 03:53",
  "2024-03-18 03:54",
  "2024-03-18 03:55",
  "2024-03-18 03:56",
  "2024-03-18 03:57",
  "2024-03-18 03:58",
  "2024-03-18 03:59",
  "2024-03-18 04:00",
  "2024-03-18 04:01",
  "2024-03-18 04:02",
  "2024-03-18 04:03",
  "2024-03-18 04:04",
  "2024-03-18 04:05",
  "2024-03-18 04:06",
  "2024-03-18 04:07",
  "2024-03-18 04:08",
  "2024-03-18 04:09",
  "2024-03-18 04:10",
  "2024-03-18 04:11",
  "2024-03-18 04:12",
  "2024-03-18 04:13",
  "2024-03-18 04:14",
  "2024-03-18 04:15",
  "2024-03-18 04:16",
  "2024-03-18 04:17",
  "2024-03-18 04:18",
  "2024-03-18 04:19",
  "2024-03-18 04:20",
  "2024-03-18 04:21",
  "2024-03-18 04:22",
  "2024-03-18 04:23",
  "2024-03-18 04:24",
  "2024-03-18 04:25",
  "2024-03-18 04:26",
  "2024-03-18 04:27",
  "2024-03-18 04:28",
  "2024-03-18 04:29",
  "2024-03-18 04:30",
  "2024-03-18 04:31",
  "2024-03-18 04:32",
  "2024-03-18 04:33",
  "2024-03-18 04:34",
  "2024-03-18 04:35",
  "2024-03-18 04:36",
  "2024-03-18 04:37",
  "2024-03-18 04:38",
  "2024-03-18 04:39",
  "2024-03-18 04:40",
  "2024-03-18 04:41",
  "2024-03-18 04:42",
  "2024-03-18 04:43",
  "2024-03-18 04:44",
  "2024-03-18 04:45",
  "2024-03-18 04:46",
  "2024-03-18 04:47",
  "2024-03-18 04:48",
  "2024-03-18 04:49",
  "2024-03-18 04:50",
  "2024-03-18 04:51",
  "2024-03-18 04:52",
  "2024-03-18 04:53",
  "2024-03-18 04:54",
  "2024-03-18 04:55",
  "2024-03-18 04:56",
  "2024-03-18 04:57",
  "2024-03-18 04:58",
  "2024-03-18 04:59",
  "2024-03-18 05:00",
  "2024-03-18 05:01",
  "2024-03-18 05:02",
  "2024-03-18 05:03",
  "2024-03-18 05:04",
  "2024-03-18 05:05",
  "2024-03-18 05:06",
  "2024-03-18 05:07",
  "2024-03-18 05:08",
  "2024-03-18 05:09",
  "2024-03-18 05:10",
  "2024-03-18 05:11",
  "2024-03-18 05:12",
  "2024-03-18 05:13",
  "2024-03-18 05:14",
  "2024-03-18 05:15",
  "2024-03-18 05:16",
  "2024-03-18 05:17",
  "2024-03-18 05:18",
  "2024-03-18 05:19",
  "2024-03-18 05:20",
  "2024-03-18 05:21",
  "2024-03-18 05:22",
  "2024-03-18 05:23",
  "2024-03-18 05:24",
  "2024-03-18 05:25",
  "2024-03-18 05:26",
  "2024-03-18 05:27",
  "2024-03-18 05:28",
  "2024-03-18 05:29",
  "2024-03-18 05:30",
  "2024-03-18 05:31",
  "2024-03-18 05:32",
  "2024-03-18 05:33",
  "2024-03-18 05:34",
  "2024-03-18 05:35",
  "2024-03-18 05:36",
  "2024-03-18 05:37",
  "2024-03-18 05:38",
  "2024-03-18 05:39",
  "2024-03-18 05:40",
  "2024-03-18 05:41",
  "2024-03-18 05:42",
  "2024-03-18 05:43",
  "2024-03-18 05:44",
  "2024-03-18 05:45",
  "2024-03-18 05:46",
  "2024-03-18 05:47",
  "2024-03-18 05:48",
  "2024-03-18 05:49",
  "2024-03-18 05:50",
  "2024-03-18 05:51",
  "2024-03-18 05:52",
  "2024-03-18 05:53",
  "2024-03-18 05:54",
  "2024-03-18 05:55",
  "2024-03-18 05:56",
  "2024-03-18 05:57",
  "2024-03-18 05:58",
  "2024-03-18 05:59",
  "2024-03-18 06:00",
  "2024-03-18 06:01",
  "2024-03-18 06:02",
  "2024-03-18 06:03",
  "2024-03-18 06:04",
  "2024-03-18 06:05",
  "2024-03-18 06:06",
  "2024-03-18 06:07",
  "2024-03-18 06:08",
  "2024-03-18 06:09",
  "2024-03-18 06:10",
  "2024-03-18 06:11",
  "2024-03-18 06:12",
  "2024-03-18 06:13",
  "2024-03-18 06:14",
  "2024-03-18 06:15",
  "2024-03-18 06:16",
  "2024-03-18 06:17",
  "2024-03-18 06:18",
  "2024-03-18 06:19",
  "2024-03-18 06:20",
  "2024-03-18 06:21",
  "2024-03-18 06:22",
  "2024-03-18 06:23",
  "2024-03-18 06:24",
  "2024-03-18 06:25",
  "2024-03-18 06:26",
  "2024-03-18 06:27",
  "2024-03-18 06:28",
  "2024-03-18 06:29",
  "2024-03-18 06:30",
  "2024-03-18 06:31",
  "2024-03-18 06:32",
  "2024-03-18 06:33",
  "2024-03-18 06:34",
  "2024-03-18 06:35",
  "2024-03-18 06:36",
  "2024-03-18 06:37",
  "2024-03-18 06:38",
  "2024-03-18 06:39",
  "2024-03-18 06:40",
  "2024-03-18 06:41",
  "2024-03-18 06:42",
  "2024-03-18 06:43",
  "2024-03-18 06:44",
  "2024-03-18 06:45",
  "2024-03-18 06:46",
  "2024-03-18 06:47",
  "2024-03-18 06:48",
  "2024-03-18 06:49",
  "2024-03-18 06:50",
  "2024-03-18 06:51",
  "2024-03-18 06:52",
  "2024-03-18 06:53",
  "2024-03-18 06:54",
  "2024-03-18 06:55",
  "2024-03-18 06:56",
  "2024-03-18 06:57",
  "2024-03-18 06:58",
  "2024-03-18 06:59",
  "2024-03-18 07:00",
  "2024-03-18 07:01",
  "2024-03-18 07:02",
  "2024-03-18 07:03",
  "2024-03-18 07:04",
  "2024-03-18 07:05",
  "2024-03-18 07:06",
  "2024-03-18 07:07",
  "2024-03-18 07:08",
  "2024-03-18 07:09",
  "2024-03-18 07:10",
  "2024-03-18 07:11",
  "2024-03-18 07:12",
  "2024-03-18 07:13",
  "2024-03-18 07:14",
  "2024-03-18 07:15",
  "2024-03-18 07:16",
  "2024-03-18 07:17",
  "2024-03-18 07:18",
  "2024-03-18 07:19",
  "2024-03-18 07:20",
  "2024-03-18 07:21",
  "2024-03-18 07:22",
  "2024-03-18 07:23",
  "2024-03-18 07:24",
  "2024-03-18 07:25",
  "2024-03-18 07:26",
  "2024-03-18 07:27",
  "2024-03-18 07:28",
  "2024-03-18 07:29",
  "2024-03-18 07:30",
  "2024-03-18 07:31",
  "2024-03-18 07:32",
  "2024-03-18 07:33",
  "2024-03-18 07:34",
  "2024-03-18 07:35",
  "2024-03-18 07:36",
  "2024-03-18 07:37",
  "2024-03-18 07:38",
  "2024-03-18 07:39",
  "2024-03-18 07:40",
  "2024-03-18 07:41",
  "2024-03-18 07:42",
  "2024-03-18 07:43",
  "2024-03-18 07:44",
  "2024-03-18 07:45",
  "2024-03-18 07:46",
  "2024-03-18 07:47",
  "2024-03-18 07:48",
  "2024-03-18 07:49",
  "2024-03-18 07:50",
  "2024-03-18 07:51",
  "2024-03-18 07:52",
  "2024-03-18 07:53",
  "2024-03-18 07:54",
  "2024-03-18 07:55",
  "2024-03-18 07:56",
  "2024-03-18 07:57",
  "2024-03-18 07:58",
  "2024-03-18 07:59",
  "2024-03-18 08:00",
  "2024-03-18 08:01",
  "2024-03-18 08:02",
  "2024-03-18 08:03",
  "2024-03-18 08:04",
  "2024-03-18 08:05",
  "2024-03-18 08:06",
  "2024-03-18 08:07",
  "2024-03-18 08:08",
  "2024-03-18 08:09",
  "2024-03-18 08:10",
  "2024-03-18 08:11",
  "2024-03-18 08:12",
  "2024-03-18 08:13",
  "2024-03-18 08:14",
  "2024-03-18 08:15",
  "2024-03-18 08:16",
  "2024-03-18 08:17",
  "2024-03-18 08:18",
  "2024-03-18 08:19",
  "2024-03-18 08:20",
  "2024-03-18 08:21",
  "2024-03-18 08:22",
  "2024-03-18 08:23",
  "2024-03-18 08:24",
  "2024-03-18 08:25",
  "2024-03-18 08:26",
  "2024-03-18 08:27",
  "2024-03-18 08:28",
  "2024-03-18 08:29",
  "2024-03-18 08:30",
  "2024-03-18 08:31",
  "2024-03-18 08:32",
  "2024-03-18 08:33",
  "2024-03-18 08:34",
  "2024-03-18 08:35",
  "2024-03-18 08:36",
  "2024-03-18 08:37",
  "2024-03-18 08:38",
  "2024-03-18 08:39",
  "2024-03-18 08:40",
  "2024-03-18 08:41",
  "2024-03-18 08:42",
  "2024-03-18 08:43",
  "2024-03-18 08:44",
  "2024-03-18 08:45",
  "2024-03-18 08:46",
  "2024-03-18 08:47",
  "2024-03-18 08:48",
  "2024-03-18 08:49",
  "2024-03-18 08:50",
  "2024-03-18 08:51",
  "2024-03-18 08:52",
  "2024-03-18 08:53",
  "2024-03-18 08:54",
  "2024-03-18 08:55",
  "2024-03-18 08:56",
  "2024-03-18 08:57",
  "2024-03-18 08:58",
  "2024-03-18 08:59",
  "2024-03-18 09:00",
  "2024-03-18 09:01",
  "2024-03-18 09:02",
  "2024-03-18 09:03",
  "2024-03-18 09:04",
  "2024-03-18 09:05",
  "2024-03-18 09:06",
  "2024-03-18 09:07",
  "2024-03-18 09:08",
  "2024-03-18 09:09",
  "2024-03-18 09:10",
  "2024-03-18 09:11",
  "2024-03-18 09:12",
  "2024-03-18 09:13",
  "2024-03-18 09:14",
  "2024-03-18 09:15",
  "2024-03-18 09:16",
  "2024-03-18 09:17",
  "2024-03-18 09:18",
  "2024-03-18 09:19",
  "2024-03-18 09:20",
  "2024-03-18 09:21",
  "2024-03-18 09:22",
  "2024-03-18 09:23",
  "2024-03-18 09:24",
  "2024-03-18 09:25",
  "2024-03-18 09:26",
  "2024-03-18 09:27",
  "2024-03-18 09:28",
  "2024-03-18 09:29",
  "2024-03-18 09:30",
  "2024-03-18 09:31",
  "2024-03-18 09:32",
  "2024-03-18 09:33",
  "2024-03-18 09:34",
  "2024-03-18 09:35",
  "2024-03-18 09:36",
  "2024-03-18 09:37",
  "2024-03-18 09:38",
  "2024-03-18 09:39",
  "2024-03-18 09:40",
  "2024-03-18 09:41",
  "2024-03-18 09:42",
  "2024-03-18 09:43",
  "2024-03-18 09:44",
  "2024-03-18 09:45",
  "2024-03-18 09:46",
  "2024-03-18 09:47",
  "2024-03-18 09:48",
  "2024-03-18 09:49",
  "2024-03-18 09:50",
  "2024-03-18 09:51",
  "2024-03-18 09:52",
  "2024-03-18 09:53",
  "2024-03-18 09:54",
  "2024-03-18 09:55",
  "2024-03-18 09:56",
  "2024-03-18 09:57",
  "2024-03-18 09:58",
  "2024-03-18 09:59",
  "2024-03-18 10:00",
  "2024-03-18 10:01",
  "2024-03-18 10:02",
  "2024-03-18 10:03",
  "2024-03-18 10:04",
  "2024-03-18 10:05",
  "2024-03-18 10:06",
  "2024-03-18 10:07",
  "2024-03-18 10:08",
  "2024-03-18 10:09",
  "2024-03-18 10:10",
  "2024-03-18 10:11",
  "2024-03-18 10:12",
  "2024-03-18 10:13",
  "2024-03-18 10:14",
  "2024-03-18 10:15",
  "2024-03-18 10:16",
  "2024-03-18 10:17",
  "2024-03-18 10:18",
  "2024-03-18 10:19",
  "2024-03-18 10:20",
  "2024-03-18 10:21",
  "2024-03-18 10:22",
  "2024-03-18 10:23",
  "2024-03-18 10:24",
  "2024-03-18 10:25",
  "2024-03-18 10:26",
  "2024-03-18 10:27",
  "2024-03-18 10:28",
  "2024-03-18 10:29",
  "2024-03-18 10:30",
  "2024-03-18 10:31",
  "2024-03-18 10:32",
  "2024-03-18 10:33",
  "2024-03-18 10:34",
  "2024-03-18 10:35",
  "2024-03-18 10:36",
  "2024-03-18 10:37",
  "2024-03-18 10:38",
  "2024-03-18 10:39",
  "2024-03-18 10:40",
  "2024-03-18 10:41",
  "2024-03-18 10:42",
  "2024-03-18 10:43",
  "2024-03-18 10:44",
  "2024-03-18 10:45",
  "2024-03-18 10:46",
  "2024-03-18 10:47",
  "2024-03-18 10:48",
  "2024-03-18 10:49",
  "2024-03-18 10:50",
  "2024-03-18 10:51",
  "2024-03-18 10:52",
  "2024-03-18 10:53",
  "2024-03-18 10:54",
  "2024-03-18 10:55",
  "2024-03-18 10:56",
  "2024-03-18 10:57",
  "2024-03-18 10:58",
  "2024-03-18 10:59",
  "2024-03-18 11:00",
  "2024-03-18 11:01",
  "2024-03-18 11:02",
  "2024-03-18 11:03",
  "2024-03-18 11:04",
  "2024-03-18 11:05",
  "2024-03-18 11:06",
  "2024-03-18 11:07",
  "2024-03-18 11:08",
  "2024-03-18 11:09",
  "2024-03-18 11:10",
  "2024-03-18 11:11",
  "2024-03-18 11:12",
  "2024-03-18 11:13",
  "2024-03-18 11:14",
  "2024-03-18 11:15",
  "2024-03-18 11:16",
  "2024-03-18 11:17",
  "2024-03-18 11:18",
  "2024-03-18 11:19",
  "2024-03-18 11:20",
  "2024-03-18 11:21",
  "2024-03-18 11:22",
  "2024-03-18 11:23",
  "2024-03-18 11:24",
  "2024-03-18 11:25",
  "2024-03-18 11:26",
  "2024-03-18 11:27",
  "2024-03-18 11:28",
  "2024-03-18 11:29",
  "2024-03-18 11:30",
  "2024-03-18 11:31",
  "2024-03-18 11:32",
  "2024-03-18 11:33",
  "2024-03-18 11:34",
  "2024-03-18 11:35",
  "2024-03-18 11:36",
  "2024-03-18 11:37",
  "2024-03-18 11:38",
  "2024-03-18 11:39",
  "2024-03-18 11:40",
  "2024-03-18 11:41",
  "2024-03-18 11:42",
  "2024-03-18 11:43",
  "2024-03-18 11:44",
  "2024-03-18 11:45",
  "2024-03-18 11:46",
  "2024-03-18 11:47",
  "2024-03-18 11:48",
  "2024-03-18 11:49",
  "2024-03-18 11:50",
  "2024-03-18 11:51",
  "2024-03-18 11:52",
  "2024-03-18 11:53",
  "2024-03-18 11:54",
  "2024-03-18 11:55",
  "2024-03-18 11:56",
  "2024-03-18 11:57",
  "2024-03-18 11:58",
  "2024-03-18 11:59",
  "2024-03-18 12:00",
  "2024-03-18 12:01",
  "2024-03-18 12:02",
  "2024-03-18 12:03",
  "2024-03-18 12:04",
  "2024-03-18 12:05",
  "2024-03-18 12:06",
  "2024-03-18 12:07",
  "2024-03-18 12:08",
  "2024-03-18 12:09",
  "2024-03-18 12:10",
  "2024-03-18 12:11",
  "2024-03-18 12:12",
  "2024-03-18 12:13",
  "2024-03-18 12:14",
  "2024-03-18 12:15",
  "2024-03-18 12:16",
  "2024-03-18 12:17",
  "2024-03-18 12:18",
  "2024-03-18 12:19",
  "2024-03-18 12:20",
  "2024-03-18 12:21",
  "2024-03-18 12:22",
  "2024-03-18 12:23",
  "2024-03-18 12:24",
  "2024-03-18 12:25",
  "2024-03-18 12:26",
  "2024-03-18 12:27",
  "2024-03-18 12:28",
  "2024-03-18 12:29",
  "2024-03-18 12:30",
  "2024-03-18 12:31",
  "2024-03-18 12:32",
  "2024-03-18 12:33",
  "2024-03-18 12:34",
  "2024-03-18 12:35",
  "2024-03-18 12:36",
  "2024-03-18 12:37",
  "2024-03-18 12:38",
  "2024-03-18 12:39",
  "2024-03-18 12:40",
  "2024-03-18 12:41",
  "2024-03-18 12:42",
  "2024-03-18 12:43",
  "2024-03-18 12:44",
  "2024-03-18 12:45",
  "2024-03-18 12:46",
  "2024-03-18 12:47",
  "2024-03-18 12:48",
  "2024-03-18 12:49",
  "2024-03-18 12:50",
  "2024-03-18 12:51",
  "2024-03-18 12:52",
  "2024-03-18 12:53",
  "2024-03-18 12:54",
  "2024-03-18 12:55",
  "2024-03-18 12:56",
  "2024-03-18 12:57",
  "2024-03-18 12:58",
  "2024-03-18 12:59",
  "2024-03-18 13:00",
  "2024-03-18 13:01",
  "2024-03-18 13:02",
  "2024-03-18 13:03",
  "2024-03-18 13:04",
  "2024-03-18 13:05",
  "2024-03-18 13:06",
  "2024-03-18 13:07",
  "2024-03-18 13:08",
  "2024-03-18 13:09",
  "2024-03-18 13:10",
  "2024-03-18 13:11",
  "2024-03-18 13:12",
  "2024-03-18 13:13",
  "2024-03-18 13:14",
  "2024-03-18 13:15",
  "2024-03-18 13:16",
  "2024-03-18 13:17",
  "2024-03-18 13:18",
  "2024-03-18 13:19",
  "2024-03-18 13:20",
  "2024-03-18 13:21",
  "2024-03-18 13:22",
  "2024-03-18 13:23",
  "2024-03-18 13:24",
  "2024-03-18 13:25",
  "2024-03-18 13:26",
  "2024-03-18 13:27",
  "2024-03-18 13:28",
  "2024-03-18 13:29",
  "2024-03-18 13:30",
  "2024-03-18 13:31",
  "2024-03-18 13:32",
  "2024-03-18 13:33",
  "2024-03-18 13:34",
  "2024-03-18 13:35",
  "2024-03-18 13:36",
  "2024-03-18 13:37",
  "2024-03-18 13:38",
  "2024-03-18 13:39",
  "2024-03-18 13:40",
  "2024-03-18 13:41",
  "2024-03-18 13:42",
  "2024-03-18 13:43",
  "2024-03-18 13:44",
  "2024-03-18 13:45",
  "2024-03-18 13:46",
  "2024-03-18 13:47",
  "2024-03-18 13:48",
  "2024-03-18 13:49",
  "2024-03-18 13:50",
  "2024-03-18 13:51",
  "2024-03-18 13:52",
  "2024-03-18 13:53",
  "2024-03-18 13:54",
  "2024-03-18 13:55",
  "2024-03-18 13:56",
  "2024-03-18 13:57",
  "2024-03-18 13:58",
  "2024-03-18 13:59",
  "2024-03-18 14:00",
  "2024-03-18 14:01",
  "2024-03-18 14:02",
  "2024-03-18 14:03",
  "2024-03-18 14:04",
  "2024-03-18 14:05",
  "2024-03-18 14:06",
  "2024-03-18 14:07",
  "2024-03-18 14:08",
  "2024-03-18 14:09",
  "2024-03-18 14:10",
  "2024-03-18 14:11",
  "2024-03-18 14:12",
  "2024-03-18 14:13",
  "2024-03-18 14:14",
  "2024-03-18 14:15",
  "2024-03-18 14:16",
  "2024-03-18 14:17",
  "2024-03-18 14:18",
  "2024-03-18 14:19",
  "2024-03-18 14:20",
  "2024-03-18 14:21",
  "2024-03-18 14:22",
  "2024-03-18 14:23",
  "2024-03-18 14:24",
  "2024-03-18 14:25",
  "2024-03-18 14:26",
  "2024-03-18 14:27",
  "2024-03-18 14:28",
  "2024-03-18 14:29",
  "2024-03-18 14:30",
  "2024-03-18 14:31",
  "2024-03-18 14:32",
  "2024-03-18 14:33",
  "2024-03-18 14:34",
  "2024-03-18 14:35",
  "2024-03-18 14:36",
  "2024-03-18 14:37",
  "2024-03-18 14:38",
  "2024-03-18 14:39",
  "2024-03-18 14:40",
  "2024-03-18 14:41",
  "2024-03-18 14:42",
  "2024-03-18 14:43",
  "2024-03-18 14:44",
  "2024-03-18 14:45",
  "2024-03-18 14:46",
  "2024-03-18 14:47",
  "2024-03-18 14:48",
  "2024-03-18 14:49",
  "2024-03-18 14:50",
  "2024-03-18 14:51",
  "2024-03-18 14:52",
  "2024-03-18 14:53",
  "2024-03-18 14:54",
  "2024-03-18 14:55",
  "2024-03-18 14:56",
  "2024-03-18 14:57",
  "2024-03-18 14:58",
  "2024-03-18 14:59",
  "2024-03-18 15:00",
  "2024-03-18 15:01",
  "2024-03-18 15:02",
  "2024-03-18 15:03",
  "2024-03-18 15:04",
  "2024-03-18 15:05",
  "2024-03-18 15:06",
  "2024-03-18 15:07"
]
		})
		const methods = {
			charts(){
				var myChart = echarts.init(document.getElementById('main'));

				// 初始化数据，data1和data2分别表示两条曲线的数据
				var data1 = [];
				var maxDataTime = 15; // 设置数据老化时间

				// 创建初始数据
				function createInitialData() {
					var now = new Date();
					for (var i = 0; i < 15; i++) {
						var time = new Date(now - i * 1000);
						data1.unshift([time.getTime(),(Math.random() * 100).toFixed(0)]); // 新数据在数组前面插入

					}
				}
				createInitialData()

				// createInitialData(); // 创建初始数据

				// 更新数据的定时器，每秒增加一个新数据，删除一个最老的数据
				setInterval(function () {
					var now = new Date();
					var time = new Date(now);
					// data1.push([time.getTime(), (Math.random() * 100).toFixed(0)]); // 新数据在数组前面插入
					var random_time = Math.random() * 100
					// data1.push([time.getTime(), Math.random() * 100]);
					// data1.push([time.getTime() + random_time * 3, Math.random() * 100]);
					data1.push([time.getTime() + random_time * 6, Math.floor(Math.random() * 10) + 1]);
					// console.log(data1,'data1');
					// data2.push([time.getTime(), (Math.random() * 100).toFixed(0)]);
					// data2.push([time.getTime() + random_time * 2, Math.random() * 100]);
					// data2.push([time.getTime() + random_time * 4, Math.random() * 100]);
					// data2.push([time.getTime() + random_time * 6, Math.random() * 100]);
					// data2.push([time.getTime() + random_time * 8, Math.random() * 100]);

					while (data1.length > 0) {
						if (time.getTime() - data1[0][0] > maxDataTime * 1000) {
							data1.shift(); // 删除最老的数据
						} else {
							break; // 跳出循环
						}
					}
					


					// 计算最小值
					var maxXValue = Math.max(...data1.map(item => item[0])); // 获取数据1和数据2的x轴的最大值
					var minValue = maxXValue - maxDataTime * 1000; // 计算最小值，减去一个固定值

					// 更新图表，设置切换动画效果
					myChart.setOption({
						tooltip:{
							trigger: 'axis',
							axisPointer: { type: 'line' },
							confine: true,
						},
						xAxis: {
							min: minValue, // 设置x轴的最小值
						},
						series: [
							{
								data: data1,
								animation: false, // 禁用动画
							},

						]
					});
				}, 1000); // 定时器每秒执行一次



				// 配置折线图的选项
				var option = {
					xAxis: {
						type: 'time', // x轴类型设置为时间轴
						boundaryGap: false, // 时间轴不留白
						min: 'dataMin', // x轴最小值根据数据动态调整
						max: 'dataMax', // x轴最大值根据数据动态调整
					},
					yAxis: {
						type: 'value', // y轴类型设置为数值轴
					},
					series: [
						{
							name: '曲线1',
							type: 'line', // 折线图
							data: data1,
							symbolSize: 10, // 设置折线上的点的大小
						},
						// {
						// 	name: '曲线2',
						// 	type: 'line', // 折线图
						// 	data: data2,
						// 	symbolSize: 10, // 设置折线上的点的大小
						// }
					]
				};

				// 使用配置项展示图表
				myChart.setOption(option);

			}
		}
		onMounted(() => {
			methods.charts()
		})
		return { ...toRefs(state), ...methods }
	}
}
</script>

<style lang="scss" scoped>
.echarts{
	width: 100%;
	height: 100%;
}
.startstop{
	height: 33%;
	width: 100%;
}
.shishi{
	width: 100%;
	height: 33%;
	.main{
		width: 100%;
		height: 100%;
	}
}
.chat{
	width: 100%; 
	height: 33%;
}
</style>