<template>
	<el-table :data="dataList"  max-height="600"
		 size="mini">
	
		<el-table-column v-for="(item, index) in dataList" v-if="index >= 3" :label="index + 1 + ''" align="center">
			<el-table-column width="90" :label="item.colName" :prop="item.col" align="center">
			
				<template v-slot:header>
					<el-tooltip :content="item.colName" placement="top-start" effect="light">
						<span>{{ item.colName }}</span>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table-column>
	</el-table>
</template>

<script>
import 'swiper/css';
export default {
	data() {
		return {
			column: [
				{
					prop: "name",
					label: "姓名",
					tooltip: true,
					width: "",
					tooltip: true
				},
				{
					prop: "date",
					label: "日期",
					width: "",
					tooltip: true

				},
				{
					prop: "address",
					label: "地址",
					width: "",
				},
				{
					prop: "zip",
					label: "邮政编号",
					width: "",
				},
				{
					slot: "status",
				},
				{
					slot: "opt",
				},
			],
			showList:[
			{
        "uuid": "3005",
        "col": "ROLL_PLAN_NO",
        "colName": "轧制计划号",
        "colShowName": "轧制计划号",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 5,
        "analysisIsList": 5,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 5,
        "analysisIsContrast": null,
        "traceIsList": 6,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },	
			],
			dataList: [
    {
        "uuid": "3005",
        "col": "ROLL_PLAN_NO",
        "colName": "轧制计划号",
        "colShowName": "轧制计划号",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 5,
        "analysisIsList": 5,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 5,
        "analysisIsContrast": null,
        "traceIsList": 6,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3006",
        "col": "LOT_SEQ",
        "colName": "轧制计划顺序号",
        "colShowName": "轧制计划顺序号",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 6,
        "analysisIsList": 6,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3007",
        "col": "HEAT_NO",
        "colName": "炉次号",
        "colShowName": "炉次号",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 7,
        "analysisIsList": 7,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 7,
        "analysisIsContrast": null,
        "traceIsList": 8,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3012",
        "col": "CCM_EQP_NO",
        "colName": "连铸机号",
        "colShowName": "连铸机号",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 12,
        "analysisIsList": 12,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 12,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3016",
        "col": "ROLL_BILLET_CNT",
        "colName": "轧批钢坯数",
        "colShowName": "轧批钢坯数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 16,
        "analysisIsList": 16,
        "analysisIsYaxis": 16,
        "analysisIsXaxis": null,
        "analysisIsContrast": 16,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 16,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3017",
        "col": "IN_FUR_BILLET_CNT",
        "colName": "入炉钢坯数",
        "colShowName": "入炉钢坯数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 17,
        "analysisIsList": 17,
        "analysisIsYaxis": 17,
        "analysisIsXaxis": null,
        "analysisIsContrast": 17,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 17,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3018",
        "col": "OUT_FUR_BILLET_CNT",
        "colName": "出炉钢坯数",
        "colShowName": "出炉钢坯数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 18,
        "analysisIsList": 18,
        "analysisIsYaxis": 18,
        "analysisIsXaxis": null,
        "analysisIsContrast": 18,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 18,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3019",
        "col": "RE_FUR_BILLET_CNT",
        "colName": "再装炉支数",
        "colShowName": "再装炉支数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 19,
        "analysisIsList": 19,
        "analysisIsYaxis": 19,
        "analysisIsXaxis": null,
        "analysisIsContrast": 19,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 19,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3020",
        "col": "MILL_BILLET_CNT",
        "colName": "轧制钢坯数",
        "colShowName": "轧制钢坯数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 20,
        "analysisIsList": 20,
        "analysisIsYaxis": 20,
        "analysisIsXaxis": null,
        "analysisIsContrast": 20,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 20,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3021",
        "col": "RLLBCK_BILLET_CNT",
        "colName": "回退钢坯数",
        "colShowName": "回退钢坯数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 21,
        "analysisIsList": 21,
        "analysisIsYaxis": 21,
        "analysisIsXaxis": null,
        "analysisIsContrast": 21,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 21,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3022",
        "col": "CNCL_BILLET_CNT",
        "colName": "吊销钢坯数",
        "colShowName": "吊销钢坯数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 22,
        "analysisIsList": 22,
        "analysisIsYaxis": 22,
        "analysisIsXaxis": null,
        "analysisIsContrast": 22,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 22,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3023",
        "col": "SCRAP_ROLL_BILLET_CNT",
        "colName": "轧废钢坯数",
        "colShowName": "轧废钢坯数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 23,
        "analysisIsList": 23,
        "analysisIsYaxis": 23,
        "analysisIsXaxis": null,
        "analysisIsContrast": 23,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 23,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3024",
        "col": "SCRAP_SAW_BILLET_CNT",
        "colName": "切废钢坯数",
        "colShowName": "切废钢坯数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 24,
        "analysisIsList": 24,
        "analysisIsYaxis": 24,
        "analysisIsXaxis": null,
        "analysisIsContrast": 24,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 24,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3028",
        "col": "STL_GRD_NM",
        "colName": "钢种名称",
        "colShowName": "牌号",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 28,
        "analysisIsList": 28,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 28,
        "analysisIsContrast": null,
        "traceIsList": 29,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3030",
        "col": "STD_SPEC",
        "colName": "执行标准号",
        "colShowName": "出钢记号",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 30,
        "analysisIsList": 30,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 30,
        "analysisIsContrast": null,
        "traceIsList": 31,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3039",
        "col": "BILLET_LTH_SET",
        "colName": "钢坯长度（设定）",
        "colShowName": "钢坯长度（设定）",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 39,
        "analysisIsList": 39,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 39,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3044",
        "col": "BILLET_SPEC_SET",
        "colName": "钢坯规格（设定）",
        "colShowName": "钢坯规格",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 44,
        "analysisIsList": 44,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 44,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 44,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3049",
        "col": "PROD_LTH_SET",
        "colName": "成品长度（设定）",
        "colShowName": "成品长度（设定）",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 49,
        "analysisIsList": 49,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3050",
        "col": "PROD_SPEC_SET",
        "colName": "成品规格（设定）",
        "colShowName": "成品规格",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 50,
        "analysisIsList": 50,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 50,
        "analysisIsContrast": null,
        "traceIsList": 51,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 50,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3055",
        "col": "IN_BILLET_WGT",
        "colName": "入炉钢坯重量",
        "colShowName": "入炉钢坯重量",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 55,
        "analysisIsList": 55,
        "analysisIsYaxis": 55,
        "analysisIsXaxis": null,
        "analysisIsContrast": 55,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 55,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3056",
        "col": "PROD_PACK_CNT",
        "colName": "产出成品捆数",
        "colShowName": "产出成品捆数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 56,
        "analysisIsList": 56,
        "analysisIsYaxis": 56,
        "analysisIsXaxis": null,
        "analysisIsContrast": 56,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 56,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3057",
        "col": "PROD_PIECE_CNT",
        "colName": "产出成品支数",
        "colShowName": "产出成品支数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 57,
        "analysisIsList": 57,
        "analysisIsYaxis": 57,
        "analysisIsXaxis": null,
        "analysisIsContrast": 57,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 57,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3058",
        "col": "PROD_WGT",
        "colName": "产出成品重量",
        "colShowName": "产出成品重量",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 58,
        "analysisIsList": 58,
        "analysisIsYaxis": 58,
        "analysisIsXaxis": null,
        "analysisIsContrast": 58,
        "traceIsList": 59,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 58,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3059",
        "col": "CHARG_TIME",
        "colName": "首支装炉时间",
        "colShowName": "首支装炉时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 59,
        "analysisIsList": 59,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 59,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3060",
        "col": "PRE_HEAT_TIME",
        "colName": "进预热段时间",
        "colShowName": "首支进加热一段时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 60,
        "analysisIsList": 60,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 60,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3061",
        "col": "F_HEAT_TIME",
        "colName": "进加热一段时间",
        "colShowName": "首支进加热二段时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 61,
        "analysisIsList": 61,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 61,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3062",
        "col": "S_HEAT_TIME",
        "colName": "进加热二段时间",
        "colShowName": "首支进加热三段时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 62,
        "analysisIsList": 62,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 62,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3063",
        "col": "SOAK_HEAT_TIME",
        "colName": "进均热段时间",
        "colShowName": "首支进均热段时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 63,
        "analysisIsList": 63,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 63,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3064",
        "col": "FIRST_DISCHARGE_TIME",
        "colName": "首支出炉时间",
        "colShowName": "首支出炉时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 64,
        "analysisIsList": 64,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 64,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3065",
        "col": "LAST_CHARGE_TIME",
        "colName": "末支装炉时间",
        "colShowName": "末支装炉时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 65,
        "analysisIsList": 65,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 65,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3066",
        "col": "LAST_HEAT1_TIME",
        "colName": "末支进加热一时间",
        "colShowName": "末支进加热一段时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 66,
        "analysisIsList": 66,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 66,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3067",
        "col": "LAST_HEAT2_TIME",
        "colName": "末支进加热二段时间",
        "colShowName": "末支进加热二段时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 67,
        "analysisIsList": 67,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 67,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3068",
        "col": "LAST_HEAT3_TIME",
        "colName": "末支进加热三段时间",
        "colShowName": "末支进加热三段时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 68,
        "analysisIsList": 68,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 68,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3069",
        "col": "LAST_DISCH_TIME",
        "colName": "末支进均热段时间",
        "colShowName": "末支进均热段时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 69,
        "analysisIsList": 69,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 69,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3070",
        "col": "DISCHARG_TIME",
        "colName": "末支出炉时间",
        "colShowName": "末支出炉时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 70,
        "analysisIsList": 70,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": 61,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 70,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3071",
        "col": "ROLL_STR_TIME",
        "colName": "首支开轧时间",
        "colShowName": "首支开轧时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 71,
        "analysisIsList": 71,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 71,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3072",
        "col": "ROLL_END_TIME",
        "colName": "末支终轧时间",
        "colShowName": "末支终轧时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 72,
        "analysisIsList": 72,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": 73,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 72,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3073",
        "col": "RP_STR_TIME",
        "colName": "首支除磷开始时间",
        "colShowName": "首支除磷开始时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 73,
        "analysisIsList": 73,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 73,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3074",
        "col": "RP_END_TIME",
        "colName": "末支除磷结束时间",
        "colShowName": "末支除磷结束时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 74,
        "analysisIsList": 74,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 74,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3075",
        "col": "RR_STR_TIME",
        "colName": "首支开坯开始时间",
        "colShowName": "首支开坯开始时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 75,
        "analysisIsList": 75,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 75,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3076",
        "col": "RR_END_TIME",
        "colName": "末支开坯结束时间",
        "colShowName": "末支开坯结束时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 76,
        "analysisIsList": 76,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 76,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3077",
        "col": "PR_STR_TIME",
        "colName": "首支连轧开始时间",
        "colShowName": "首支连轧开始时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 77,
        "analysisIsList": 77,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 77,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3078",
        "col": "PR_END_TIME",
        "colName": "末支连轧结束时间",
        "colShowName": "末支连轧结束时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 78,
        "analysisIsList": 78,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 78,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3079",
        "col": "LOAD_GB_TIME",
        "colName": "首支上编组台架时间",
        "colShowName": "首支上编组台架时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 79,
        "analysisIsList": 79,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 79,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3080",
        "col": "UNLOAD_GB_TIME",
        "colName": "末支下编组台架时间",
        "colShowName": "末支下编组台架时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 80,
        "analysisIsList": 80,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 80,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3081",
        "col": "LOAD_CB_TIME",
        "colName": "首支上冷床时间",
        "colShowName": "首支上冷床时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 81,
        "analysisIsList": 81,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 81,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3082",
        "col": "UNLOAD_CB_TIME",
        "colName": "末支下冷床时间",
        "colShowName": "末支下冷床时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 82,
        "analysisIsList": 82,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 82,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3083",
        "col": "PRE_HOLD_DUR",
        "colName": "预热段时长",
        "colShowName": "加热一段时长",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 83,
        "analysisIsList": 83,
        "analysisIsYaxis": 83,
        "analysisIsXaxis": null,
        "analysisIsContrast": 83,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 83,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3084",
        "col": "F_HEAT_HOLD_DUR",
        "colName": "加热一段时长",
        "colShowName": "加热二段时长",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 84,
        "analysisIsList": 84,
        "analysisIsYaxis": 84,
        "analysisIsXaxis": null,
        "analysisIsContrast": 84,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 84,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3085",
        "col": "S_HEAT_HOLD_DUR",
        "colName": "加热二段时长",
        "colShowName": "加热三段时长",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 85,
        "analysisIsList": 85,
        "analysisIsYaxis": 85,
        "analysisIsXaxis": null,
        "analysisIsContrast": 85,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 85,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3086",
        "col": "SOAK_HEAT_HOLD_DUR",
        "colName": "均热段时长",
        "colShowName": "均热段时长",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 86,
        "analysisIsList": 86,
        "analysisIsYaxis": 86,
        "analysisIsXaxis": null,
        "analysisIsContrast": 86,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 86,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3087",
        "col": "TTL_FUR_DUR",
        "colName": "总加热时长",
        "colShowName": "总加热时长",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 87,
        "analysisIsList": 87,
        "analysisIsYaxis": 87,
        "analysisIsXaxis": null,
        "analysisIsContrast": 87,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 87,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3088",
        "col": "TTL_FUR_DUR_QUAL_CNT",
        "colName": "总加热时长-合格数",
        "colShowName": "总加热时长-合格数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 88,
        "analysisIsList": 88,
        "analysisIsYaxis": 88,
        "analysisIsXaxis": null,
        "analysisIsContrast": 88,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 88,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3089",
        "col": "OUT_BILLET_DUR_MIN",
        "colName": "出钢间隔-最小值",
        "colShowName": "出钢间隔-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 89,
        "analysisIsList": 89,
        "analysisIsYaxis": 89,
        "analysisIsXaxis": null,
        "analysisIsContrast": 89,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 89,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3090",
        "col": "OUT_BILLET_DUR_AVG",
        "colName": "出钢间隔-平均值",
        "colShowName": "出钢间隔-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 90,
        "analysisIsList": 90,
        "analysisIsYaxis": 90,
        "analysisIsXaxis": null,
        "analysisIsContrast": 90,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 90,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3091",
        "col": "OUT_BILLET_DUR_MAX",
        "colName": "出钢间隔-最大值",
        "colShowName": "出钢间隔-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 91,
        "analysisIsList": 91,
        "analysisIsYaxis": 91,
        "analysisIsXaxis": null,
        "analysisIsContrast": 91,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 91,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3092",
        "col": "TTL_ROLL_DUR",
        "colName": "总轧制时长",
        "colShowName": "总轧制时长",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 92,
        "analysisIsList": 92,
        "analysisIsYaxis": 92,
        "analysisIsXaxis": null,
        "analysisIsContrast": 92,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 92,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3093",
        "col": "TTL_CB_DUR",
        "colName": "总冷床时长",
        "colShowName": "总冷床时长",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "CB_CREW_CD",
        "sort": 93,
        "analysisIsList": 93,
        "analysisIsYaxis": 93,
        "analysisIsXaxis": null,
        "analysisIsContrast": 93,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 93,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3094",
        "col": "IN_TEMP_MIN",
        "colName": "入炉温度-最小值",
        "colShowName": "入炉温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 94,
        "analysisIsList": 94,
        "analysisIsYaxis": 94,
        "analysisIsXaxis": null,
        "analysisIsContrast": 94,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 94,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3095",
        "col": "IN_TEMP_AVG",
        "colName": "入炉温度-平均值",
        "colShowName": "入炉温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 95,
        "analysisIsList": 95,
        "analysisIsYaxis": 95,
        "analysisIsXaxis": null,
        "analysisIsContrast": 95,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 95,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3096",
        "col": "IN_TEMP_MAX",
        "colName": "入炉温度-最大值",
        "colShowName": "入炉温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 96,
        "analysisIsList": 96,
        "analysisIsYaxis": 96,
        "analysisIsXaxis": null,
        "analysisIsContrast": 96,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 96,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3101",
        "col": "P_HEAT_TEMP_MIN",
        "colName": "进预热段温度-最小值",
        "colShowName": "进加热一段温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 101,
        "analysisIsList": 101,
        "analysisIsYaxis": 101,
        "analysisIsXaxis": null,
        "analysisIsContrast": 101,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 101,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3102",
        "col": "P_HEAT_TEMP_AVG",
        "colName": "进预热段温度-平均值",
        "colShowName": "进加热一段温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 102,
        "analysisIsList": 102,
        "analysisIsYaxis": 102,
        "analysisIsXaxis": null,
        "analysisIsContrast": 102,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 102,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3103",
        "col": "P_HEAT_TEMP_MAX",
        "colName": "进预热段温度-最大值",
        "colShowName": "进加热一段温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 103,
        "analysisIsList": 103,
        "analysisIsYaxis": 103,
        "analysisIsXaxis": null,
        "analysisIsContrast": 103,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 103,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3104",
        "col": "P_HEAT_TEMP_LOWER",
        "colName": "预热段温度下限",
        "colShowName": "加热一段温度下限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 104,
        "analysisIsList": 104,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3106",
        "col": "P_HEAT_TEMP_UPPER",
        "colName": "预热段温度上限",
        "colShowName": "加热一段温度上限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 106,
        "analysisIsList": 106,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3107",
        "col": "P_HEAT_TEMP_QUAL_CNT",
        "colName": "预热段温度-合格数",
        "colShowName": "加热一段温度-合格数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 107,
        "analysisIsList": 107,
        "analysisIsYaxis": 107,
        "analysisIsXaxis": null,
        "analysisIsContrast": 107,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 107,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3108",
        "col": "F_HEAT_TEMP_MIN",
        "colName": "进加热一段温度-最小值",
        "colShowName": "进加热二段温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 108,
        "analysisIsList": 108,
        "analysisIsYaxis": 108,
        "analysisIsXaxis": null,
        "analysisIsContrast": 108,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 108,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3109",
        "col": "F_HEAT_TEMP_AVG",
        "colName": "进加热一段温度-平均值",
        "colShowName": "进加热二段温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 109,
        "analysisIsList": 109,
        "analysisIsYaxis": 109,
        "analysisIsXaxis": null,
        "analysisIsContrast": 109,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 109,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3110",
        "col": "F_HEAT_TEMP_MAX",
        "colName": "进加热一段温度-最大值",
        "colShowName": "进加热二段温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 110,
        "analysisIsList": 110,
        "analysisIsYaxis": 110,
        "analysisIsXaxis": null,
        "analysisIsContrast": 110,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 110,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3111",
        "col": "F_HEAT_TEMP_LOWER",
        "colName": "加热一段温度下限",
        "colShowName": "加热二段温度下限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 111,
        "analysisIsList": 111,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3112",
        "col": "F_HEAT_TEMP_TARGET",
        "colName": "加热一段温度目标值",
        "colShowName": "加热二段温度目标值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 112,
        "analysisIsList": 112,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3113",
        "col": "F_HEAT_TEMP_UPPER",
        "colName": "加热一段温度上限",
        "colShowName": "加热二段温度上限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 113,
        "analysisIsList": 113,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3114",
        "col": "F_HEAT_TEMP_QUAL_CNT",
        "colName": "加热一段温度-合格数",
        "colShowName": "加热二段温度-合格数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 114,
        "analysisIsList": 114,
        "analysisIsYaxis": 114,
        "analysisIsXaxis": null,
        "analysisIsContrast": 114,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 114,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3115",
        "col": "S_HEAT_TEMP_MIN",
        "colName": "进加热二段温度-最小值",
        "colShowName": "进加热三段温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 115,
        "analysisIsList": 115,
        "analysisIsYaxis": 115,
        "analysisIsXaxis": null,
        "analysisIsContrast": 115,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 115,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3116",
        "col": "S_HEAT_TEMP_AVG",
        "colName": "进加热二段温度-平均值",
        "colShowName": "进加热三段温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 116,
        "analysisIsList": 116,
        "analysisIsYaxis": 116,
        "analysisIsXaxis": null,
        "analysisIsContrast": 116,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 116,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3117",
        "col": "S_HEAT_TEMP_MAX",
        "colName": "进加热二段温度-最大值",
        "colShowName": "进加热三段温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 117,
        "analysisIsList": 117,
        "analysisIsYaxis": 117,
        "analysisIsXaxis": null,
        "analysisIsContrast": 117,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 117,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3118",
        "col": "S_HEAT_TEMP_LOWER",
        "colName": "加热二段温度下限",
        "colShowName": "加热三段温度下限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 118,
        "analysisIsList": 118,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3119",
        "col": "S_HEAT_TEMP_TARGET",
        "colName": "加热二段温度目标值",
        "colShowName": "加热三段温度目标值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 119,
        "analysisIsList": 119,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3120",
        "col": "S_HEAT_TEMP_UPPER",
        "colName": "加热二段温度上限",
        "colShowName": "加热三段温度上限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 120,
        "analysisIsList": 120,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3121",
        "col": "S_HEAT_TEMP_QUAL_CNT",
        "colName": "加热二段温度-合格数",
        "colShowName": "加热三段温度-合格数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "IN_CREW_CD",
        "sort": 121,
        "analysisIsList": 121,
        "analysisIsYaxis": 121,
        "analysisIsXaxis": null,
        "analysisIsContrast": 121,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 121,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3122",
        "col": "SOAK_HEAT_TEMP_MIN",
        "colName": "进均热段温度-最小值",
        "colShowName": "进均热段温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 122,
        "analysisIsList": 122,
        "analysisIsYaxis": 122,
        "analysisIsXaxis": null,
        "analysisIsContrast": 122,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 122,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3123",
        "col": "SOAK_HEAT_TEMP_AVG",
        "colName": "进均热段温度-平均值",
        "colShowName": "进均热段温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 123,
        "analysisIsList": 123,
        "analysisIsYaxis": 123,
        "analysisIsXaxis": null,
        "analysisIsContrast": 123,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 123,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3124",
        "col": "SOAK_HEAT_TEMP_MAX",
        "colName": "进均热段温度-最大值",
        "colShowName": "进均热段温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 124,
        "analysisIsList": 124,
        "analysisIsYaxis": 124,
        "analysisIsXaxis": null,
        "analysisIsContrast": 124,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 124,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3125",
        "col": "SOAK_HEAT_TEMP_LOWER",
        "colName": "均热段温度下限",
        "colShowName": "均热段温度下限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 125,
        "analysisIsList": 125,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3126",
        "col": "SOAK_HEAT_TEMP_TARGET",
        "colName": "均热段温度目标值",
        "colShowName": "均热段温度目标值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 126,
        "analysisIsList": 126,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3127",
        "col": "SOAK_HEAT_TEMP_UPPER",
        "colName": "均热段温度上限",
        "colShowName": "均热段温度上限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 127,
        "analysisIsList": 127,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3128",
        "col": "SOAK_HEAT_TEMP_QUAL_CNT",
        "colName": "均热段温度-合格数",
        "colShowName": "均热段温度-合格数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 128,
        "analysisIsList": 128,
        "analysisIsYaxis": 128,
        "analysisIsXaxis": null,
        "analysisIsContrast": 128,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 128,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3129",
        "col": "OUT_TEMP_MIN",
        "colName": "出炉温度-最小值",
        "colShowName": "出炉温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 129,
        "analysisIsList": 129,
        "analysisIsYaxis": 129,
        "analysisIsXaxis": null,
        "analysisIsContrast": 129,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 129,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3130",
        "col": "OUT_TEMP_AVG",
        "colName": "出炉温度-平均值",
        "colShowName": "出炉温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 130,
        "analysisIsList": 130,
        "analysisIsYaxis": 130,
        "analysisIsXaxis": null,
        "analysisIsContrast": 130,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 130,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3131",
        "col": "OUT_TEMP_MAX",
        "colName": "出炉温度-最大值",
        "colShowName": "出炉温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 131,
        "analysisIsList": 131,
        "analysisIsYaxis": 131,
        "analysisIsXaxis": null,
        "analysisIsContrast": 131,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 131,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3132",
        "col": "OUT_TEMP_LOWER",
        "colName": "出炉温度下限",
        "colShowName": "出炉温度下限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 132,
        "analysisIsList": 132,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3133",
        "col": "OUT_TEMP_TARGET",
        "colName": "出炉温度目标值",
        "colShowName": "出炉温度目标值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 133,
        "analysisIsList": 133,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3134",
        "col": "OUT_TEMP_UPPER",
        "colName": "出炉温度上限",
        "colShowName": "出炉温度上限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 134,
        "analysisIsList": 134,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3135",
        "col": "OUT_TEMP_QUAL_CNT",
        "colName": "出炉温度-合格数",
        "colShowName": "出炉温度-合格数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "OUT_CREW_CD",
        "sort": 135,
        "analysisIsList": 135,
        "analysisIsYaxis": 135,
        "analysisIsXaxis": null,
        "analysisIsContrast": 135,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 135,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3136",
        "col": "RP_OUT_TEMP_MIN",
        "colName": "除磷出口温度-最小值",
        "colShowName": "除磷出口温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 136,
        "analysisIsList": 136,
        "analysisIsYaxis": 136,
        "analysisIsXaxis": null,
        "analysisIsContrast": 136,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 136,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3137",
        "col": "RP_OUT_TEMP_AVG",
        "colName": "除磷出口温度-平均值",
        "colShowName": "除磷出口温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 137,
        "analysisIsList": 137,
        "analysisIsYaxis": 137,
        "analysisIsXaxis": null,
        "analysisIsContrast": 137,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 137,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3138",
        "col": "RP_OUT_TEMP_MAX",
        "colName": "除磷出口温度-最大值",
        "colShowName": "除磷出口温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 138,
        "analysisIsList": 138,
        "analysisIsYaxis": 138,
        "analysisIsXaxis": null,
        "analysisIsContrast": 138,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 138,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3139",
        "col": "RR_STR_TEMP_MIN",
        "colName": "开坯入口温度-最小值",
        "colShowName": "开坯入口温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 139,
        "analysisIsList": 139,
        "analysisIsYaxis": 139,
        "analysisIsXaxis": null,
        "analysisIsContrast": 139,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 139,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3140",
        "col": "RR_STR_TEMP_AVG",
        "colName": "开坯入口温度-平均值",
        "colShowName": "开坯入口温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 140,
        "analysisIsList": 140,
        "analysisIsYaxis": 140,
        "analysisIsXaxis": null,
        "analysisIsContrast": 140,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 140,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3141",
        "col": "RR_STR_TEMP_MAX",
        "colName": "开坯入口温度-最大值",
        "colShowName": "开坯入口温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 141,
        "analysisIsList": 141,
        "analysisIsYaxis": 141,
        "analysisIsXaxis": null,
        "analysisIsContrast": 141,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 141,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3142",
        "col": "RR_STR_TEMP_LOWER",
        "colName": "开坯入口温度下限",
        "colShowName": "开坯入口温度下限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 142,
        "analysisIsList": 142,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3143",
        "col": "RR_STR_TEMP_TARGET",
        "colName": "开坯入口温度目标值",
        "colShowName": "开坯入口温度目标值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 143,
        "analysisIsList": 143,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3144",
        "col": "RR_STR_TEMP_UPPER",
        "colName": "开坯入口温度上限",
        "colShowName": "开坯入口温度上限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 144,
        "analysisIsList": 144,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3145",
        "col": "RR_STR_TEMP_QUAL_CNT",
        "colName": "开坯入口温度-合格数",
        "colShowName": "开坯入口温度-合格数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 145,
        "analysisIsList": 145,
        "analysisIsYaxis": 145,
        "analysisIsXaxis": null,
        "analysisIsContrast": 145,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 145,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3146",
        "col": "RR_1ST_TEMP_MIN",
        "colName": "开坯第一道次后温度-最小值",
        "colShowName": "开坯第一道次后温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 146,
        "analysisIsList": 146,
        "analysisIsYaxis": 146,
        "analysisIsXaxis": null,
        "analysisIsContrast": 146,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 146,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3147",
        "col": "RR_1ST_TEMP_AVG",
        "colName": "开坯第一道次后温度-平均值",
        "colShowName": "开坯第一道次后温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 147,
        "analysisIsList": 147,
        "analysisIsYaxis": 147,
        "analysisIsXaxis": null,
        "analysisIsContrast": 147,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 147,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3148",
        "col": "RR_1ST_TEMP_MAX",
        "colName": "开坯第一道次后温度-最大值",
        "colShowName": "开坯第一道次后温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 148,
        "analysisIsList": 148,
        "analysisIsYaxis": 148,
        "analysisIsXaxis": null,
        "analysisIsContrast": 148,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 148,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3149",
        "col": "RR_1ST_TEMP_LOWER",
        "colName": "开坯第一道次后温度下限",
        "colShowName": "开坯第一道次后温度下限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 149,
        "analysisIsList": 149,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3150",
        "col": "RR_1ST_TEMP_TARGET",
        "colName": "开坯第一道次后温度目标值",
        "colShowName": "开坯第一道次后温度目标值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 150,
        "analysisIsList": 150,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3151",
        "col": "RR_1ST_TEMP_UPPER",
        "colName": "开坯第一道次后温度上限",
        "colShowName": "开坯第一道次后温度上限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 151,
        "analysisIsList": 151,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3152",
        "col": "RR_END_TEMP_MIN",
        "colName": "开坯出口温度-最小值",
        "colShowName": "开坯出口温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 152,
        "analysisIsList": 152,
        "analysisIsYaxis": 152,
        "analysisIsXaxis": null,
        "analysisIsContrast": 152,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 152,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3153",
        "col": "RR_END_TEMP_AVG",
        "colName": "开坯出口温度-平均值",
        "colShowName": "开坯出口温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 153,
        "analysisIsList": 153,
        "analysisIsYaxis": 153,
        "analysisIsXaxis": null,
        "analysisIsContrast": 153,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 153,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3154",
        "col": "RR_END_TEMP_MAX",
        "colName": "开坯出口温度-最大值",
        "colShowName": "开坯出口温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 154,
        "analysisIsList": 154,
        "analysisIsYaxis": 154,
        "analysisIsXaxis": null,
        "analysisIsContrast": 154,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 154,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3155",
        "col": "RR_END_TEMP_LOWER",
        "colName": "开坯出口温度下限",
        "colShowName": "开坯出口温度下限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 155,
        "analysisIsList": 155,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3156",
        "col": "RR_END_TEMP_TARGET",
        "colName": "开坯出口温度目标值",
        "colShowName": "开坯出口温度目标值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 156,
        "analysisIsList": 156,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3157",
        "col": "RR_END_TEMP_UPPER",
        "colName": "开坯出口温度上限",
        "colShowName": "开坯出口温度上限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 157,
        "analysisIsList": 157,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3158",
        "col": "RR_END_TEMP_QUAL_CNT",
        "colName": "开坯出口温度-合格数",
        "colShowName": "开坯出口温度-合格数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 158,
        "analysisIsList": 158,
        "analysisIsYaxis": 158,
        "analysisIsXaxis": null,
        "analysisIsContrast": 158,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 158,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3159",
        "col": "PR_STR_TEMP_MIN",
        "colName": "连轧入口温度-最小值",
        "colShowName": "连轧入口温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 159,
        "analysisIsList": 159,
        "analysisIsYaxis": 159,
        "analysisIsXaxis": null,
        "analysisIsContrast": 159,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 159,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3160",
        "col": "PR_STR_TEMP_AVG",
        "colName": "连轧入口温度-平均值",
        "colShowName": "连轧入口温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 160,
        "analysisIsList": 160,
        "analysisIsYaxis": 160,
        "analysisIsXaxis": null,
        "analysisIsContrast": 160,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 160,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3161",
        "col": "PR_STR_TEMP_MAX",
        "colName": "连轧入口温度-最大值",
        "colShowName": "连轧入口温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 161,
        "analysisIsList": 161,
        "analysisIsYaxis": 161,
        "analysisIsXaxis": null,
        "analysisIsContrast": 161,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 161,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3162",
        "col": "PR_STR_TEMP_LOWER",
        "colName": "连轧入口温度下限",
        "colShowName": "连轧入口温度下限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 162,
        "analysisIsList": 162,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3163",
        "col": "PR_STR_TEMP_TARGET",
        "colName": "连轧入口温度目标值",
        "colShowName": "连轧入口温度目标值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 163,
        "analysisIsList": 163,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3164",
        "col": "PR_STR_TEMP_UPPER",
        "colName": "连轧入口温度上限",
        "colShowName": "连轧入口温度上限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 164,
        "analysisIsList": 164,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3165",
        "col": "PR_STR_TEMP_QUAL_CNT",
        "colName": "连轧入口温度-合格数",
        "colShowName": "连轧入口温度-合格数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 165,
        "analysisIsList": 165,
        "analysisIsYaxis": 165,
        "analysisIsXaxis": null,
        "analysisIsContrast": 165,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 165,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3166",
        "col": "PR_END_TEMP_MIN",
        "colName": "连轧出口温度-最小值",
        "colShowName": "连轧出口温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 166,
        "analysisIsList": 166,
        "analysisIsYaxis": 166,
        "analysisIsXaxis": null,
        "analysisIsContrast": 166,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 166,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3167",
        "col": "PR_END_TEMP_AVG",
        "colName": "连轧出口温度-平均值",
        "colShowName": "连轧出口温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 167,
        "analysisIsList": 167,
        "analysisIsYaxis": 167,
        "analysisIsXaxis": null,
        "analysisIsContrast": 167,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 167,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3168",
        "col": "PR_END_TEMP_MAX",
        "colName": "连轧出口温度-最大值",
        "colShowName": "连轧出口温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 168,
        "analysisIsList": 168,
        "analysisIsYaxis": 168,
        "analysisIsXaxis": null,
        "analysisIsContrast": 168,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 168,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3169",
        "col": "PR_END_TEMP_LOWER",
        "colName": "连轧出口温度下限",
        "colShowName": "连轧出口温度下限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 169,
        "analysisIsList": 169,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3170",
        "col": "PR_END_TEMP_TARGET",
        "colName": "连轧出口温度目标值",
        "colShowName": "连轧出口温度目标值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 170,
        "analysisIsList": 170,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3171",
        "col": "PR_END_TEMP_UPPER",
        "colName": "连轧出口温度上限",
        "colShowName": "连轧出口温度上限",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 171,
        "analysisIsList": 171,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3172",
        "col": "PR_END_TEMP_QUAL_CNT",
        "colName": "连轧出口温度-合格数",
        "colShowName": "连轧出口温度-合格数",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 172,
        "analysisIsList": 172,
        "analysisIsYaxis": 172,
        "analysisIsXaxis": null,
        "analysisIsContrast": 172,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 172,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3173",
        "col": "LOAD_GB_TEMP_MIN",
        "colName": "上编组台架温度-最小值",
        "colShowName": "上编组台架温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 173,
        "analysisIsList": 173,
        "analysisIsYaxis": 173,
        "analysisIsXaxis": null,
        "analysisIsContrast": 173,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 173,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3174",
        "col": "LOAD_GB_TEMP_AVG",
        "colName": "上编组台架温度-平均值",
        "colShowName": "上编组台架温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 174,
        "analysisIsList": 174,
        "analysisIsYaxis": 174,
        "analysisIsXaxis": null,
        "analysisIsContrast": 174,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 174,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3175",
        "col": "LOAD_GB_TEMP_MAX",
        "colName": "上编组台架温度-最大值",
        "colShowName": "上编组台架温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 175,
        "analysisIsList": 175,
        "analysisIsYaxis": 175,
        "analysisIsXaxis": null,
        "analysisIsContrast": 175,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 175,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3176",
        "col": "UNLOAD_GB_TEMP_MIN",
        "colName": "下编组台架温度-最小值",
        "colShowName": "下编组台架温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 176,
        "analysisIsList": 176,
        "analysisIsYaxis": 176,
        "analysisIsXaxis": null,
        "analysisIsContrast": 176,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 176,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3177",
        "col": "UNLOAD_GB_TEMP_AVG",
        "colName": "下编组台架温度-平均值",
        "colShowName": "下编组台架温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 177,
        "analysisIsList": 177,
        "analysisIsYaxis": 177,
        "analysisIsXaxis": null,
        "analysisIsContrast": 177,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 177,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3178",
        "col": "UNLOAD_GB_TEMP_MAX",
        "colName": "下编组台架温度-最大值",
        "colShowName": "下编组台架温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 178,
        "analysisIsList": 178,
        "analysisIsYaxis": 178,
        "analysisIsXaxis": null,
        "analysisIsContrast": 178,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 178,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3179",
        "col": "SAW_TEMP_01_MIN",
        "colName": "第一次锯切温度-最小值",
        "colShowName": "第一次锯切温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 179,
        "analysisIsList": 179,
        "analysisIsYaxis": 179,
        "analysisIsXaxis": null,
        "analysisIsContrast": 179,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 179,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3180",
        "col": "SAW_TEMP_01_AVG",
        "colName": "第一次锯切温度-平均值",
        "colShowName": "第一次锯切温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 180,
        "analysisIsList": 180,
        "analysisIsYaxis": 180,
        "analysisIsXaxis": null,
        "analysisIsContrast": 180,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 180,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3181",
        "col": "SAW_TEMP_01_MAX",
        "colName": "第一次锯切温度-最大值",
        "colShowName": "第一次锯切温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "ROLL_CREW_CD",
        "sort": 181,
        "analysisIsList": 181,
        "analysisIsYaxis": 181,
        "analysisIsXaxis": null,
        "analysisIsContrast": 181,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 181,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3182",
        "col": "LOAD_CB_TEMP_MIN",
        "colName": "上冷床温度-最小值",
        "colShowName": "上冷床温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "CB_CREW_CD",
        "sort": 182,
        "analysisIsList": 182,
        "analysisIsYaxis": 182,
        "analysisIsXaxis": null,
        "analysisIsContrast": 182,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 182,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3183",
        "col": "LOAD_CB_TEMP_AVG",
        "colName": "上冷床温度-平均值",
        "colShowName": "上冷床温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "CB_CREW_CD",
        "sort": 183,
        "analysisIsList": 183,
        "analysisIsYaxis": 183,
        "analysisIsXaxis": null,
        "analysisIsContrast": 183,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 183,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3184",
        "col": "LOAD_CB_TEMP_MAX",
        "colName": "上冷床温度-最大值",
        "colShowName": "上冷床温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "CB_CREW_CD",
        "sort": 184,
        "analysisIsList": 184,
        "analysisIsYaxis": 184,
        "analysisIsXaxis": null,
        "analysisIsContrast": 184,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 184,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3185",
        "col": "UNLOAD_CB_TEMP_MIN",
        "colName": "下冷床温度-最小值",
        "colShowName": "下冷床温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "CB_CREW_CD",
        "sort": 185,
        "analysisIsList": 185,
        "analysisIsYaxis": 185,
        "analysisIsXaxis": null,
        "analysisIsContrast": 185,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 185,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3186",
        "col": "UNLOAD_CB_TEMP_AVG",
        "colName": "下冷床温度-平均值",
        "colShowName": "下冷床温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "CB_CREW_CD",
        "sort": 186,
        "analysisIsList": 186,
        "analysisIsYaxis": 186,
        "analysisIsXaxis": null,
        "analysisIsContrast": 186,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 186,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3187",
        "col": "UNLOAD_CB_TEMP_MAX",
        "colName": "下冷床温度-最大值",
        "colShowName": "下冷床温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": "CB_CREW_CD",
        "sort": 187,
        "analysisIsList": 187,
        "analysisIsYaxis": 187,
        "analysisIsXaxis": null,
        "analysisIsContrast": 187,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 187,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3207",
        "col": "IN_CREW",
        "colName": "入炉班组",
        "colShowName": "入炉班组",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 207,
        "analysisIsList": 207,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 207,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 207,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3208",
        "col": "IN_SHIFT",
        "colName": "入炉班次",
        "colShowName": "入炉班次",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 208,
        "analysisIsList": 208,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 208,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 208,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3212",
        "col": "OUT_CREW",
        "colName": "出炉班组",
        "colShowName": "出炉班组",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 212,
        "analysisIsList": 212,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 212,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 212,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3213",
        "col": "OUT_SHIFT",
        "colName": "出炉班次",
        "colShowName": "出炉班次",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 213,
        "analysisIsList": 213,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 213,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "F",
        "traceWpBaseInfo": 213,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3217",
        "col": "ROLL_CREW",
        "colName": "轧制班组",
        "colShowName": "轧制班组",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 217,
        "analysisIsList": 217,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 217,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 217,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3218",
        "col": "ROLL_SHIFT",
        "colName": "轧制班次",
        "colShowName": "轧制班次",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 218,
        "analysisIsList": 218,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 218,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 218,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3221",
        "col": "CB_CREW",
        "colName": "冷床班组",
        "colShowName": "冷床班组",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 221,
        "analysisIsList": 221,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 221,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 221,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3222",
        "col": "CB_SHIFT",
        "colName": "冷床班次",
        "colShowName": "冷床班次",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 222,
        "analysisIsList": 222,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 222,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": "M",
        "traceWpBaseInfo": 222,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3393",
        "col": "RR_DUR",
        "colName": "开坯机轧制时长",
        "colShowName": "开坯机轧制时长",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 393,
        "analysisIsList": 393,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3394",
        "col": "PR_DUR",
        "colName": "连轧轧制时长",
        "colShowName": "连轧轧制时长",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 394,
        "analysisIsList": 394,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3395",
        "col": "TTL_CB_RUN_DUR",
        "colName": "冷床运行时长",
        "colShowName": "冷却时间",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 395,
        "analysisIsList": 395,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3396",
        "col": "TTL_COOL_DUR",
        "colName": "总缓冷时长",
        "colShowName": "总缓冷时长",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 396,
        "analysisIsList": 396,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3397",
        "col": "FUR_HEAT_SPEED",
        "colName": "加热速率",
        "colShowName": "加热速率",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 397,
        "analysisIsList": 397,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3398",
        "col": "CB_COOL_SPEED",
        "colName": "冷却速率",
        "colShowName": "冷却速率",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 398,
        "analysisIsList": 398,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3399",
        "col": "IN_COOL_EQP_TEMP_MIN",
        "colName": "入缓冷坑温度-最小值",
        "colShowName": "入缓冷坑温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 399,
        "analysisIsList": 399,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3400",
        "col": "IN_COOL_EQP_TEMP_AVG",
        "colName": "入缓冷坑温度-平均值",
        "colShowName": "入缓冷坑温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 400,
        "analysisIsList": 400,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3401",
        "col": "IN_COOL_EQP_TEMP_MAX",
        "colName": "入缓冷坑温度-最大值",
        "colShowName": "入缓冷坑温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 401,
        "analysisIsList": 401,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3402",
        "col": "OUT_COOL_EQP_TEMP_MIN",
        "colName": "出缓冷坑温度-最小值",
        "colShowName": "出缓冷坑温度-最小值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 402,
        "analysisIsList": 402,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3403",
        "col": "OUT_COOL_EQP_TEMP_AVG",
        "colName": "出缓冷坑温度-平均值",
        "colShowName": "出缓冷坑温度-平均值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 403,
        "analysisIsList": 403,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3404",
        "col": "OUT_COOL_EQP_TEMP_MAX",
        "colName": "出缓冷坑温度-最大值",
        "colShowName": "出缓冷坑温度-最大值",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 404,
        "analysisIsList": 404,
        "analysisIsYaxis": null,
        "analysisIsXaxis": null,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "NUMERIC",
        "analysisIsPass": null
    },
    {
        "uuid": "3405",
        "col": "MONTH",
        "colName": "年月",
        "colShowName": "年月",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 405,
        "analysisIsList": 405,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 405,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3406",
        "col": "SPARE_1",
        "colName": "是否发生质量异议",
        "colShowName": "是否发生质量异议",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 406,
        "analysisIsList": 406,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 406,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3407",
        "col": "SPARE_2",
        "colName": "是否存在质量缺陷",
        "colShowName": "是否存在质量缺陷",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 407,
        "analysisIsList": 407,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 407,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "VARCHAR",
        "analysisIsPass": null
    },
    {
        "uuid": "3408",
        "col": "SPARE_3",
        "colName": "性能判定是否合格",
        "colShowName": "性能判定是否合格",
        "tableName": "LYUAN.LYUAN_ANALYSIS_LOT_INFO_GA",
        "tableCname": "轧批信息",
        "groupName": null,
        "sort": 408,
        "analysisIsList": 408,
        "analysisIsYaxis": null,
        "analysisIsXaxis": 408,
        "analysisIsContrast": null,
        "traceIsList": null,
        "traceIsBaseInfo": null,
        "proLineType": "B4",
        "wpCode": null,
        "traceWpBaseInfo": null,
        "colType": "VARCHAR",
        "analysisIsPass": null
    }
],

		};
	},
	components: {

	},
	methods: {
		onSwiper(swiper) {
			console.log(swiper);
		},
		onSlideChange() {
			console.log('slide change');
		}
	}
}
</script>

<style lang="scss" scoped>
.mySwiper {
	width: 500px;
	height: 500px;
}
</style>