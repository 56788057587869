<template>
	<div class="item">404</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
	.item{
		color:#71829e;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 108px;
		margin-top: -100px;
	}
</style>